import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCustomerDetails,
  resetData,
} from "../../states/Slice/CustomerDetails/customerDetails.slice";
import { AppDispatch, RootState } from "../../states/store";
import { CustomerUpdate } from "../../states/Slice/UpdateCustomer/updateCustomer.slice";
import "./CustomerDetails.css";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomerDetailsPage = () => {
  const { mobileNumber } = useParams<{ mobileNumber: string }>();
  const dispatch: AppDispatch = useDispatch();
  const {
    CardSuccess: customerDetails,
    CardLoading: loading,
    CardError: error,
  } = useSelector((state: RootState) => state.customerDetails);

  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState<any>({});

  useEffect(() => {
    if (mobileNumber) {
      dispatch(fetchCustomerDetails(mobileNumber));
    }
    return () => {
      dispatch(resetData());
    };
  }, [dispatch, mobileNumber]);

  useEffect(() => {
    if (customerDetails) {
      setEditedData(customerDetails.data);
    }
  }, [customerDetails]);

  const handleInputChange = (field: string, value: any) => {
    setEditedData((prev: any) => ({
      ...prev,
      [field]: value || "",
    }));
  };
  const handleInputChangeBoolean = (field: string, value: boolean | string) => {
    setEditedData((prevData: any) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleNestedInputChange = (
    nestedField: string,
    field: string,
    value: string | number
  ) => {
    setEditedData((prev: any) => ({
      ...prev,
      [nestedField]: {
        ...prev[nestedField],
        [field]: value,
      },
    }));
  };
  const isValidDate = (dateString: string | number | Date) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  const toggleEditMode = () => {
    setIsEditing((prev) => !prev);
  };

  const handleCancelEdit = () => {
    if (customerDetails && customerDetails.data) {
      setIsEditing(false);
      setEditedData(customerDetails.data);
    }
  };

  const handleSaveChanges = () => {
    dispatch(CustomerUpdate(editedData));
    //console.log("Updated data:", editedData);
    setIsEditing(false);
  };

  return (
    <div className="customer-details-container">
      {/* <button onClick={() => window.history.back()} className="back-button">
        Go Back
      </button> */}
      <div className="edit-save-buttons">
        <button onClick={() => window.history.back()} className="back-button">
          Go Back
        </button>
        <button onClick={toggleEditMode} className="edit-button">
          {isEditing ? "Cancel" : "Edit"}
        </button>
        {isEditing && (
          <button onClick={handleSaveChanges} className="save-button">
            Save Changes
          </button>
        )}
      </div>
      <h2 className="page-title">Customer Details</h2>
      {loading && <p className="loading-text">Loading...</p>}
      {error && <p className="error-text">Error: {error}</p>}
      {customerDetails && (
        <div className="details-card">
          <div className="details-header">
            <h2 className="registration-no">
              {editedData.registrationNo || "N/A"}
            </h2>
            <img
              src={
                editedData.profilePic?.profilePicUrl || "defaultImageUrl.jpg"
              }
              alt="Customer Profile"
              className="profile-picture"
            />
          </div>
          <div className="details-content">
            <div className="details-column">
              <p>
                <strong>Full Name:</strong>{" "}
                {isEditing ? (
                  <div>
                    <div>
                      <strong>First Name:</strong>
                      <input
                        type="text"
                        placeholder="First Name"
                        value={editedData.firstName || ""}
                        onChange={(e) =>
                          handleInputChange("firstName", e.target.value)
                        }
                        className="input-field"
                      />
                    </div>
                    <div>
                      <strong>Middle Name:</strong>
                      <input
                        type="text"
                        placeholder="Middle Name"
                        value={editedData.middleName || ""}
                        onChange={(e) =>
                          handleInputChange("middleName", e.target.value)
                        }
                        className="input-field"
                      />
                    </div>
                    <div>
                      <strong>Last Name:</strong>
                      <input
                        type="text"
                        placeholder="Last Name"
                        value={editedData.lastName || ""}
                        onChange={(e) =>
                          handleInputChange("lastName", e.target.value)
                        }
                        className="input-field"
                      />
                    </div>
                  </div>
                ) : (
                  `${editedData.firstName || ""} ${
                    editedData.middleName || ""
                  } ${editedData.lastName || ""}`
                )}
              </p>
              <p>
                <strong>Mobile No:</strong>
                {isEditing ? (
                  <input
                    type="text"
                    value={editedData.primaryMobNumber || ""}
                    onChange={(e) =>
                      handleInputChange("primaryMobNumber", e.target.value)
                    }
                    className="input-field"
                  />
                ) : (
                  editedData.primaryMobNumber || "Not Provided"
                )}
              </p>
              <p>
                <strong>Father's Name:</strong>
                {isEditing ? (
                  <input
                    type="text"
                    value={editedData.familyDetails?.fathersName || ""}
                    onChange={(e) =>
                      handleNestedInputChange(
                        "familyDetails",
                        "fathersName",
                        e.target.value
                      )
                    }
                  />
                ) : (
                  editedData.familyDetails?.fathersName || "Not Provided"
                )}
              </p>
              <p>
                <strong>Father's Mobile NO:</strong>
                {isEditing ? (
                  <input
                    type="text"
                    value={editedData.familyDetails?.fathersMobileNo || ""}
                    onChange={(e) =>
                      handleNestedInputChange(
                        "familyDetails",
                        "fathersMobileNo",
                        e.target.value
                      )
                    }
                  />
                ) : (
                  editedData.familyDetails?.fathersMobileNo || "Not Provided"
                )}
              </p>

              <p>
                <strong>Date of Birth:</strong>
                {isEditing ? (
                  <ReactDatePicker
                    selected={
                      editedData.dateOfBirth &&
                      isValidDate(editedData.dateOfBirth)
                        ? new Date(editedData.dateOfBirth)
                        : null
                    }
                    onChange={(date) =>
                      handleInputChange(
                        "dateOfBirth",
                        date ? new Date(date).toISOString().split("T")[0] : ""
                      )
                    }
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Select your date of birth"
                    className="custom-datepicker"
                    showYearDropdown
                    dropdownMode="select"
                  />
                ) : (
                  editedData.dateOfBirth || "Not Provided"
                )}
              </p>
              <p>
                <strong>Gender:</strong> {editedData.gender}
              </p>
              <p>
                <strong>Occupation:</strong>
                {isEditing ? (
                  <input
                    type="text"
                    value={editedData.occupation || ""}
                    onChange={(e) =>
                      handleInputChange("occupation", e.target.value)
                    }
                  />
                ) : (
                  editedData.occupation || "Not Provided"
                )}
              </p>
              <p>
                <strong>Annual Income:</strong>
                {isEditing ? (
                  <input
                    type="text"
                    value={editedData.annualIncome || ""}
                    onChange={(e) =>
                      handleInputChange("annualIncome", e.target.value)
                    }
                  />
                ) : (
                  editedData.annualIncome || "Not Provided"
                )}
                L
              </p>
              <p>
                <strong>Farm Land:</strong>
                {isEditing ? (
                  <input
                    type="text"
                    value={editedData.agriculturalLand || ""}
                    onChange={(e) =>
                      handleInputChange("agriculturalLand", e.target.value)
                    }
                  />
                ) : (
                  editedData.agriculturalLand || "Not Provided"
                )}
              </p>
              <p>
                <strong>Height:</strong>
                {isEditing ? (
                  <select
                    className="select-80"
                    value={editedData.height || ""}
                    onChange={(e) =>
                      handleInputChange("height", e.target.value)
                    }
                  >
                    <option value="" disabled>
                      Select Height
                    </option>
                    <option value="4'0">4'0"</option>
                    <option value="4'1">4'1"</option>
                    <option value="4'2">4'2"</option>
                    <option value="4'3">4'3"</option>
                    <option value="4'4">4'4"</option>
                    <option value="4'5">4'5"</option>
                    <option value="4'6">4'6"</option>
                    <option value="4'7">4'7"</option>
                    <option value="4'8">4'8"</option>
                    <option value="4'9">4'9"</option>
                    <option value="4'10">4'10"</option>
                    <option value="4'11">4'11"</option>
                    <option value="5'0">5'0"</option>
                    <option value="5'1">5'1"</option>
                    <option value="5'2">5'2"</option>
                    <option value="5'3">5'3"</option>
                    <option value="5'4">5'4"</option>
                    <option value="5'5">5'5"</option>
                    <option value="5'6">5'6"</option>
                    <option value="5'7">5'7"</option>
                    <option value="5'8">5'8"</option>
                    <option value="5'9">5'9"</option>
                    <option value="5'10">5'10"</option>
                    <option value="5'11">5'11"</option>
                    <option value="6'0">6'0"</option>
                    <option value="6'1">6'1"</option>
                    <option value="6'2">6'2"</option>
                    <option value="6'3">6'3"</option>
                    <option value="6'4">6'4"</option>
                    <option value="6'5">6'5"</option>
                    <option value="6'6">6'6"</option>
                  </select>
                ) : (
                  editedData.height || "Not Provided"
                )}
              </p>

              <p>
                <strong>Hobby:</strong>
                {isEditing ? (
                  <input
                    type="text"
                    value={editedData.hobby || ""}
                    onChange={(e) => handleInputChange("hobby", e.target.value)}
                  />
                ) : (
                  editedData.hobby || "Not Provided"
                )}
              </p>
              <p>
                <strong>Guardian Occupation:</strong>
                {isEditing ? (
                  <input
                    type="text"
                    value={editedData.familyDetails?.fathersOccupation || ""}
                    onChange={(e) =>
                      handleNestedInputChange(
                        "familyDetails",
                        "fathersOccupation",
                        e.target.value
                      )
                    }
                  />
                ) : (
                  editedData.familyDetails?.fathersOccupation || "Not Provided"
                )}
              </p>
              <p>
                <strong>Maternal Uncle's Name:</strong>
                {isEditing ? (
                  <input
                    type="text"
                    value={editedData.familyDetails?.maternalUnclesName || ""}
                    onChange={(e) =>
                      handleNestedInputChange(
                        "familyDetails",
                        "maternalUnclesName",
                        e.target.value
                      )
                    }
                  />
                ) : (
                  editedData.familyDetails?.maternalUnclesName || "Not Provided"
                )}
              </p>
              <p>
                <strong>Mother Tongue:</strong>
                {isEditing ? (
                  <select
                    className="select-50"
                    value={editedData.motherTongue || ""}
                    onChange={(e) =>
                      handleInputChange("motherTongue", e.target.value)
                    }
                  >
                    <option value="">Select</option>
                    <option value="Marathi">Marathi</option>
                    <option value="Kannada">Kannada</option>
                    <option value="Hindi">Hindi</option>
                    <option value="Gujarati">Gujarati</option>
                    <option value="English">English</option>
                    <option value="Other">Other</option>
                  </select>
                ) : (
                  editedData.motherTongue || "Not Provided"
                )}
              </p>

              <p>
                <strong>Marital Status:</strong>
                {isEditing ? (
                  <select
                    className="select-50"
                    value={editedData.maritalStatus || ""}
                    onChange={(e) =>
                      handleInputChange("maritalStatus", e.target.value)
                    }
                  >
                    <option value="" disabled>
                      Select...
                    </option>{" "}
                    {/* Placeholder option */}
                    <option value="Single">Single</option>
                    <option value="Widowed">Widowed</option>
                    <option value="Divorced">Divorced</option>
                  </select>
                ) : (
                  editedData.maritalStatus || "Not Provided"
                )}
              </p>
              <p>
                <strong>Physical Status:</strong>
                {isEditing ? (
                  <select
                    className="select-50 "
                    value={editedData.physicalStatus || ""}
                    onChange={(e) =>
                      handleInputChange("physicalStatus", e.target.value)
                    }
                  >
                    <option value="">Select</option>
                    <option value="Normal">Normal</option>
                    <option value="Disabled">Disabled</option>
                  </select>
                ) : (
                  editedData.physicalStatus || "Not Provided"
                )}
              </p>
            </div>
            <div className="details-column">
              <p>
                <strong>Address:</strong>
                {editedData.addressDetails?.[0] ? (
                  <>
                    {editedData.addressDetails[0].addr && (
                      <span>{editedData.addressDetails[0].addr}, </span>
                    )}
                    {editedData.addressDetails[0].locality && (
                      <span>{editedData.addressDetails[0].locality}, </span>
                    )}
                    {editedData.addressDetails[0].city && (
                      <span>{editedData.addressDetails[0].city}, </span>
                    )}
                    {editedData.addressDetails[0].state && (
                      <span>{editedData.addressDetails[0].state}, </span>
                    )}
                    {editedData.addressDetails[0].country && (
                      <span>{editedData.addressDetails[0].country}, </span>
                    )}
                    {editedData.addressDetails[0].pincode && (
                      <span>{editedData.addressDetails[0].pincode}</span>
                    )}
                  </>
                ) : (
                  "Not Provided"
                )}
              </p>
              <p>
                <strong>Birth Place:</strong>
                {isEditing ? (
                  <input
                    type="text"
                    value={editedData.birthPlace || ""}
                    onChange={(e) =>
                      handleInputChange("birthPlace", e.target.value)
                    }
                  />
                ) : (
                  editedData.birthPlace || "Not Provided"
                )}
              </p>
              {/* <p>
                <strong>Birth Time: </strong>
                {editedData.birthTime}
              </p> */}

              <p>
                <strong>Education Details:</strong>
                {isEditing ? (
                  <input
                    type="text"
                    value={editedData.educationDetails || ""}
                    onChange={(e) =>
                      handleInputChange("educationDetails", e.target.value)
                    }
                  />
                ) : (
                  editedData.educationDetails || "Not Provided"
                )}
              </p>
              <p>
                <strong>Occupation City:</strong>
                {isEditing ? (
                  <input
                    type="text"
                    value={editedData.occupationCity || ""}
                    onChange={(e) =>
                      handleInputChange("occupationCity", e.target.value)
                    }
                  />
                ) : (
                  editedData.occupationCity || "Not Provided"
                )}
              </p>
              <p>
                <strong>Caste:</strong>
                {isEditing ? (
                  <select
                    className="select-50"
                    value={editedData.caste || ""}
                    onChange={(e) => handleInputChange("caste", e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Any">Any</option>
                    <option value="Digamber">Digamber</option>
                    <option value="Shwetamber">Shwetamber</option>
                  </select>
                ) : (
                  editedData.caste || "Not Provided"
                )}
              </p>

              <p>
                <strong>Subcaste:</strong>
                {isEditing ? (
                  <select
                    className="select-50"
                    value={editedData.subcaste || ""}
                    onChange={(e) =>
                      handleInputChange("subcaste", e.target.value)
                    }
                  >
                    <option value="">Select</option>
                    <option value="Chaturth">Chaturth</option>
                    <option value="Pancham">Pancham</option>
                    <option value="Shetwal">Shetwal</option>
                    <option value="Kasar">Kasar</option>
                    <option value="Dashahumad">Dashahumad</option>
                    <option value="Other">Other</option>
                  </select>
                ) : (
                  editedData.subcaste || "Not Provided"
                )}
              </p>
              <p>
                <strong>Colour of Body:</strong>
                {isEditing ? (
                  <select
                    className="select-50"
                    value={editedData.colourOfBody || ""}
                    onChange={(e) =>
                      handleInputChange("colourOfBody", e.target.value)
                    }
                  >
                    <option value="">Select</option>
                    <option value="Fair">Fair</option>
                    <option value="Wheatish">Wheatish</option>
                    <option value="Brown">Brown</option>
                  </select>
                ) : (
                  editedData.colourOfBody || "Not Provided"
                )}
              </p>
              <p>
                <strong>Ready to Marry within Subcast:</strong>
                {isEditing ? (
                  <select
                    className="select-31"
                    value={
                      editedData.readyToMarryWithinSubcast === true
                        ? "true"
                        : editedData.readyToMarryWithinSubcast === false
                        ? "false"
                        : ""
                    }
                    onChange={(e) =>
                      handleInputChangeBoolean(
                        "readyToMarryWithinSubcast",
                        e.target.value === "true" // Pass boolean here
                      )
                    }
                  >
                    <option value="">Select</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                ) : editedData.readyToMarryWithinSubcast === true ? (
                  "Yes"
                ) : editedData.readyToMarryWithinSubcast === false ? (
                  "No"
                ) : (
                  "Not Provided"
                )}
              </p>

              <p>
                <strong>Spectacles:</strong>
                {isEditing ? (
                  <select
                    className="select-31"
                    value={editedData.spec || "false"}
                    onChange={(e) => handleInputChange("spec", e.target.value)}
                  >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                ) : editedData.spec === "true" ? (
                  "Yes"
                ) : (
                  "No"
                )}
              </p>
              <p>
                <strong>Expectation:</strong>
                {isEditing ? (
                  <input
                    type="text"
                    value={editedData.expectation || ""}
                    onChange={(e) =>
                      handleInputChange("expectation", e.target.value)
                    }
                  />
                ) : (
                  editedData.expectation || "Not Provided"
                )}
              </p>

              <p>
                <strong>Employed In:</strong>
                {isEditing ? (
                  <select
                    className="select-50"
                    value={editedData.employedIn || ""}
                    onChange={(e) =>
                      handleInputChange("employedIn", e.target.value)
                    }
                  >
                    <option value="">Select</option>
                    <option value="Government">Government</option>
                    <option value="Private">Private</option>
                    <option value="Business">Business</option>
                    <option value="Self-Employed">Self-Employed</option>
                    <option value="Not Working">Not Working</option>
                  </select>
                ) : (
                  editedData.employedIn || "Not Provided"
                )}
              </p>

              <p>
                <strong>Organization:</strong>
                {isEditing ? (
                  <input
                    type="text"
                    value={editedData.organization || ""}
                    onChange={(e) =>
                      handleInputChange("organization", e.target.value)
                    }
                  />
                ) : (
                  editedData.organization || "Not Provided"
                )}
              </p>

              <p>
                <strong>House Ownership:</strong>
                {isEditing ? (
                  <select
                    className="select-50"
                    value={editedData.familyDetails?.houseOwnership || ""}
                    onChange={(e) =>
                      handleNestedInputChange(
                        "familyDetails",
                        "houseOwnership",
                        e.target.value
                      )
                    }
                  >
                    <option value="">Select</option>
                    <option value="Own">Own</option>
                    <option value="Rented">Rented</option>
                  </select>
                ) : (
                  editedData.familyDetails?.houseOwnership || "Not Provided"
                )}
              </p>

              <p>
                <strong>Relation with Guardian:</strong>
                {isEditing ? (
                  <input
                    type="text"
                    value={editedData.relationWithGuardian || ""}
                    onChange={(e) =>
                      handleInputChange("relationWithGuardian", e.target.value)
                    }
                  />
                ) : (
                  editedData.relationWithGuardian || "Not Provided"
                )}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerDetailsPage;
