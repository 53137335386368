import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { APIKEYS } from "../../../utils/Keys";
import {
  ERegister,
  IPaymentError,
  IRegisterPersonalApiResponse,
  IRegisterState,
  IUserData,
} from "../registerUser/register.models";
import { toast } from "react-toastify";
import moment from "moment";

interface IPaymentStatusState {
  paymentLoading: boolean;
  paymentSuccess: IPaymentError | null;
  paymentError: string | null;
}

const initialState: IPaymentStatusState = {
  paymentLoading: false,
  paymentSuccess: null,
  paymentError: null,
};

export const fetchPaymentStatus = createAsyncThunk<
  IRegisterPersonalApiResponse,
  IUserData,
  { rejectValue: string }
>(ERegister.REGISTER, async (data, { rejectWithValue }) => {
  try {
    const tokenData = {
      username: "jainmatrimonyapi",
      password: "jainmatrimonyapi",
    };
    // Fetch Token
    const tokenResponse = await axios.post(`${APIKEYS.API}verify`, tokenData);
    const token = tokenResponse.data.token;

    if (!token) return rejectWithValue("Token retrieval failed.");

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    // Fetch Payment Status
    const phonePayResponse = await axios.get<IPaymentError>(
      `${APIKEYS.API}phonepe-payment/${data.transactionId}`,
      { headers }
    );

    if (!phonePayResponse.data.success) {
      return rejectWithValue("Payment status fetch failed.");
    }
    const sendPostData = {
      paymentId: data.paymentId,
      paymentInstrument: phonePayResponse.data.data.paymentInstrument.type,
      utr: phonePayResponse.data.data.paymentInstrument.utr,
      status: "COMPLETED",
    };
    //console.log('sendPostData',sendPostData)
    const a = await axios.put<IRegisterPersonalApiResponse>(
      `${APIKEYS.API}phonepe-payment/${data.custId}`,
      sendPostData,
      { headers }
    );

    //console.log('sendPostData------a', a)
    sessionStorage.removeItem("paymentData");
    toast.success("Payment Successfully Verified", {
      position: "top-right",
    });
    return a.data;
  } catch (error: any) {
    console.error("Error fetching payment status:", error);
    if (axios.isAxiosError(error) && error.response) {
      return rejectWithValue(error.response.data.message);
    }
    return rejectWithValue(
      "Something went wrong while fetching payment status."
    );
  }
});
// Initial state
const userInitialState: IRegisterState = {
  registerLoading: false,
  registerSuccess: null,
  registerError: "",
};
// Slice for managing registration state
const fetchPaymentStatusSlice = createSlice({
  name: ERegister.REGISTER,
  initialState: userInitialState,
  reducers: {
    resetData: (state) => {
      state.registerSuccess = null;
      state.registerError = "";
      state.registerLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaymentStatus.pending, (state) => {
        state.registerLoading = true;
      })
      .addCase(fetchPaymentStatus.fulfilled, (state, action) => {
        state.registerLoading = false;
        state.registerSuccess = action.payload || null;
        state.registerError = null;
      })
      .addCase(fetchPaymentStatus.rejected, (state, action) => {
        state.registerLoading = false;
        state.registerError = "Something went wrong.";
      });
  },
});

// Export actions and reducer
export const { resetData } = fetchPaymentStatusSlice.actions;
export default fetchPaymentStatusSlice.reducer;
