import { combineReducers } from "@reduxjs/toolkit";
import EduSlice from "../Slice/Education/Edu.slice";
import StateSlice from "../Slice/State/State.slice";
import registerSlice from "../Slice/registerUser/register.slice";
import checkRegisterSlice from "../Slice/checkIsRegister/checkRegister.slice";
import getCardsSlice from "../Slice/GetCards/getCards.slice";
import PhonepaySlice from "../Slice/phonePay/Phonepay.slice";
import customerDetailsReducer from "../Slice/CustomerDetails/customerDetails.slice";
import paymentStatusSlice from "../Slice/PhonepayUpdateAndStatus/phonePayUpdateAndStatus.slice";
import postAdminCustomerRegistrationSlice from "../Slice/admInRegister/adminRegister.slice"
// import initiateCustomerRefundSlice from "../slice/InitiateCustomerRefund/InitiateCustomerRefundSlice";

const rootReducers = combineReducers({
  EduSlice: EduSlice,
  StateSlice: StateSlice,
  registerSlice: registerSlice,
  checkRegisterSlice: checkRegisterSlice,
  getCardsSlice: getCardsSlice,
  PhonepaySlice: PhonepaySlice,
  customerDetails: customerDetailsReducer,
  paymentStatusSlice: paymentStatusSlice,
  postAdminCustomerRegistrationSlice: postAdminCustomerRegistrationSlice,

});

export type RootState = ReturnType<typeof rootReducers>;

export default rootReducers;
