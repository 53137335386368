import React, { useState, useEffect, useRef } from 'react';
import { useForm, FormProvider, FieldValues, Path, useFormContext } from 'react-hook-form';
import './Formlayout.css'; 
import SelectInput from '../SelectInput/SelectInput.component';
import TextInput from '../TextInput/TextInput.component';
import DateTimeInput from '../DateTime/DateTime.component';
import NumberInput from '../NUMBERINPUT/NumberInput.component';
import { ReusableFormProps, FormField, TextField, SelectField, DateTimeField, NumberField, TimeField } from './ReusableModels';
import { EColor } from '../../styles/Colors/Colors';
import { useAppDispatch, useAppSelector } from '../../states/store';
import { useNavigate } from 'react-router-dom';
import { isCheckMobile } from '../../states/Slice/checkIsRegister/checkRegister.slice';
import { toast } from 'react-toastify';
import { IMobileRegisterApiResponse } from '../../states/Slice/checkIsRegister/checkRegister.models';
import { IUserData } from '../../states/Slice/registerUser/register.models';
import ReadOnlyForm from '../../Application/LogIn/ReadOnly';
import moment from 'moment';
import profilImage  from '../../assets/Images/profilepicdefault.png'
import { SelectInstance } from 'react-select';

export interface SectionsPerPage {
  [key: number]: string[];
}

export interface Icheck {
  data: []
}

const ReusableForm = <T extends FieldValues>({ fields, onSubmit,onupload,img,imgfile,isAdmin }: ReusableFormProps<T>) => {
  const methods = useForm<T>();
  const navigation = useNavigate()
const dispatch = useAppDispatch()

const firstNameRef = useRef<HTMLInputElement | null>(null);
const middleNameRef = useRef<HTMLInputElement | null>(null);
const lastNameRef = useRef<HTMLInputElement | null>(null);
const mobileNumberRef = useRef<HTMLInputElement | null>(null);
const Hobbies = useRef<HTMLInputElement | null>(null);
const AgriculturalLand = useRef<HTMLInputElement | null>(null);
const BirthPlace = useRef<HTMLInputElement | null>(null);
const Organization = useRef<HTMLInputElement | null>(null);
const annualIncome = useRef<HTMLInputElement | null>(null);
const Occupation = useRef<HTMLInputElement | null>(null);
const expectaion = useRef<HTMLInputElement | null>(null);
const guardname = useRef<HTMLInputElement | null>(null);
const relWithGuard = useRef<HTMLInputElement | null>(null);
const guardMobile = useRef<HTMLInputElement | null>(null);
const guardOcc = useRef<HTMLInputElement | null>(null);
const maternalUncle = useRef<HTMLInputElement | null>(null);
const familyAnnualIncome = useRef<HTMLInputElement | null>(null);
const familymembers = useRef<HTMLInputElement | null>(null);
const noOfBro = useRef<HTMLInputElement | null>(null);
const noOfSIsters = useRef<HTMLInputElement | null>(null);
const noOfBroMarried = useRef<HTMLInputElement | null>(null);
const noOfsisMarried = useRef<HTMLInputElement | null>(null);
const adresss = useRef<HTMLInputElement | null>(null);
const Locality = useRef<HTMLInputElement | null>(null);
const City = useRef<HTMLInputElement | null>(null);
const state = useRef<HTMLInputElement | null>(null);
const pincode = useRef<HTMLInputElement | null>(null);

const [previewData, setPreviewData] = useState<Record<string, any> | null>(null);
  const [formValues, setFormValues] = useState<Record<string, any>>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [show, setShow] = useState(false);
  const reducer = useAppSelector((state)=> state.registerSlice)
  const reducermobile = useAppSelector((state)=> state.checkRegisterSlice)
  const [error, setError] = useState<string | null>(null); // State to manage validation error

  //console.log('fffff',reducer.registerLoading);

  const sectionsPerPage: SectionsPerPage = {
    1: ['Personal Details', 'Professional Details'],
    2: ['Family Details', 'Location Details'],
  };


  const handelForm = () =>{
    window.open('/SampleForm', '_blank');
  }

  const inputRef = useRef(null);

  const maxPage = Object.keys(sectionsPerPage).length;

  const sections: { [key: string]: FormField[] } = fields.reduce((acc, field) => {
    const section = field.section || 'Default';
    if (!acc[section]) {
      acc[section] = [];
    }
    acc[section].push(field);
    return acc;
  }, {} as { [key: string]: FormField[] });


  const visibleSectionNames = sectionsPerPage[currentPage] || [];
  const visibleSections = Object.keys(sections).filter(sectionName => visibleSectionNames.includes(sectionName));

  const handleNextPage = async () => {
    //console.log('formValues',formValues)
    const isValid = await methods.trigger(); 
    //console.log('iss',isValid,)
    if (isValid) {
      setError(null); 
      const data = formValues['Mobile number']
      // console.log('mobile----',data)
      dispatch(isCheckMobile(data)).then((res)=>{
        // console.log('ressssss----',res.payload )
        const payload = res.payload as IMobileRegisterApiResponse;
        //console.log('ddd',payload.data.custId)
        if(!payload.data.custId){
          if (currentPage < maxPage) {
            setCurrentPage(currentPage + 1);
          }
        }
      })
    } else {
      setError('Please fill out all required fields on this page.'); 
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handelPrevivewPage = () =>{
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    setShow(false)
  }

// 
  // console.log('formvv',formValues['Date Of Birth'], moment(formValues['Date Of Birth'], 'HH:mm').format('hh:mm A'));

  const selectRef = useRef<SelectInstance<any, boolean>>(null); 
  const physicalStatusRef = useRef<SelectInstance<any, boolean>>(null);
  const colourRef = useRef<SelectInstance<any, boolean>>(null);

  const handleShowPreview = () => {
    const dataToSend: IUserData = {
      primaryMobNumber: formValues['Mobile number'] || '',
      firstName: formValues['Firstname'] || '',
      lastName: formValues['Lastname'] || '',
      gender: formValues['Gender'] || '',
      dateOfBirth: formValues['Date Of Birth'] ,
      birthPlace: formValues['Birth Place'] || '',
      relationWithGuardian:formValues['Releation with Guardian'] || '',
      expectation:formValues['Expectation'] || '',
      birthTime: formValues['Time'] ,
      bloodGroup: formValues['Blood Group'] || '',
      maritalStatus: formValues['Maritial Status'] || '',
      motherTongue: formValues['Mother Toungue'] || '',
      height: formValues['Height'] || '',
      weight: formValues['Weight'] || '',  
      hobby: formValues['Hobbies'] || '',
      amount:"1200",
      agriculturalLand: formValues['Agricultural Land'] || '',
      middleName: formValues['Middle name'] || '',
      uri:img,
      colourOfBody: formValues['Colour'] || '',
      spec: formValues['Spec'].toLowerCase() === 'yes' ? true : false ,
      physicalStatus: formValues['Physical Status'] || '',
      mobileNo: formValues['Mobile number'] || '',
      status: 'A', // Assuming status is always 'A'
      profilePic: null,  // Assuming profilePic is not provided in the input
      educationDetails: formValues['Education'] || '',
      File:imgfile,
      employedIn: formValues['Employed In'] || '',
      occupation: formValues['Occupation'] || '',
      occupationCity:formValues['Occupation City'] || '',
      organization: formValues['Organization'] || '',
      annualIncome: formValues['Annual Income'] || '',
      caste: formValues['Cast'] || '',
      subcaste: formValues['Sub-cast'] || '',
      familyDetails: {
        familyAnnualIncome: formValues['Family Annual Income'] || '',
        fathersName: formValues['Guardian name'] || '',
        fathersOccupation: formValues['Guardian Occupation'] || '',
        familyMembers: formValues['Family Members'] || 0,
        numberOfBrothers: formValues['No'][' of Brothers*'] || 0,
        numberOfSisters: formValues['No'][' of sister*'] || 0,
        brothersMarried: formValues['No'][' of Brothers married'] || 0,
        sistersMarried: formValues['No'][' of Sisters married'] || 0,
        houseOwnership: formValues['House'] || '',
        fathersMobileNo:formValues['Guardian Mobile Number*'] || '',
        maternalUnclesName: formValues['Maternal uncle name'] || '',
      },
      customerCred: {
        userName: formValues['Mobile number'] || '',
        password: formValues['Mobile number'] || '',
      },
      addressDetails: [{
        addr: formValues['Address*']|| '',
        city: formValues['City*'] || '',
        locality: formValues['Locality*'] || '',
        pincode: formValues['Pincode*'] || '',
        state: formValues['State'] || '',
        country:'India'
      }]
    };
    setShow(true)
    setPreviewData(dataToSend);
  };

  useEffect(() => {
    const subscription = methods.watch((value) => {
      setFormValues(value);
  
      if (value['Employed In'] === 'Not Working') {
        const fieldsToClear: Path<T>[] = ['Organization', 'Occupation', 'Annual Income'] as Path<T>[];
        methods.clearErrors(fieldsToClear);
      }
  
      validateCurrentPage();
    });
  
    return () => subscription.unsubscribe();
  }, [methods, currentPage]);
  
  // Validate the current page
  const validateCurrentPage = async () => {
    const currentPageFields = sectionsPerPage[currentPage] || [];
    const isPageValid = currentPageFields.every(sectionName => {
      const sectionFields = sections[sectionName] || [];
      return sectionFields.every(field => methods.getValues(field.name as Path<T>) !== '');
    });
    if (isPageValid) {
      setError(null); // Clear error if all fields are filled
    }
  };


  //console.warn('physss',physicalStatusRef)

  const renderField = (field: FormField): React.JSX.Element | null => {
    switch (field.type) {
      case 'text':
        return (
          <TextInput
            {...methods.register(field.name as Path<T>, { required: field.registerProps?.required })}
            key={field.name}
            isLand={formValues['Gender']}
            value={formValues['Employed In']}
            field={field as TextField}
            inputRef={
              field.name === 'Firstname'
                ? firstNameRef
                : field.name === 'Middle name'
                ? middleNameRef
                : field.name === 'Lastname'
                ? lastNameRef
                : field.name === 'Mobile number'
                ? mobileNumberRef
                : field.name === 'Hobbies'
                ? Hobbies :field.name === 'Agricultural Land'? AgriculturalLand : 
                field.name === 'Birth Place' ? BirthPlace : field.name == 'Expectation'? expectaion
                : field.name === 'Organization'? Organization : field.name === 'Annual Income'? annualIncome : field.name === 'Occupation'? Occupation :
                 field.name === 'Guardian name'? guardname : field.name === 'Releation with Guardian'? relWithGuard :field.name === 'Guardian Mobile Number*'? guardMobile
                  :
                  field.name === 'Guardian Occupation'? guardOcc
                  :
                  field.name === 'Maternal uncle name'? maternalUncle
                  :
                  field.name === 'Family Annual Income'? familyAnnualIncome
                  :
                  field.name === 'Address*'? adresss
                  :
                  field.name === 'Locality*'? Locality
                  :
                  field.name === 'City*'? City
                  :
                 undefined
            }
            onEnterPress={() => {
              if (field.name === 'Firstname') {
                middleNameRef.current?.focus();
              } else if (field.name === 'Middle name') {
                lastNameRef.current?.focus();
              } else if (field.name === 'Lastname') {
                mobileNumberRef.current?.focus();
              } else if (field.name === 'Mobile number') {
                  setTimeout(() => {
                    (selectRef.current as unknown as HTMLSelectElement)?.focus();
                  }, 100);
                }
                else if (field.name === 'Hobbies') {
                  AgriculturalLand.current?.focus();
                }
                else if (field.name === 'Agricultural Land') {
                  BirthPlace.current?.focus();
                }
                else if (field.name === 'Birth Place') {
                  expectaion.current?.focus()
                }
                else if (field.name === 'Organization') {
                  setTimeout(() => {
                    (annualIncome.current as unknown as HTMLSelectElement)?.focus();
                  }, 100);
                }
                else if (field.name === 'Annual Income') {
                  setTimeout(() => {
                    (Occupation.current as unknown as HTMLSelectElement)?.focus();
                  }, 100);
                }
                else if (field.name === 'Guardian name') {
                  setTimeout(() => {
                    (relWithGuard.current as unknown as HTMLSelectElement)?.focus();
                  }, 100);
                }
                else if (field.name === 'Releation with Guardian') {
                  setTimeout(() => {
                    (guardMobile.current as unknown as HTMLSelectElement)?.focus();
                  }, 100);
                }
                else if (field.name === 'Guardian Mobile Number*') {
                  setTimeout(() => {
                    (guardOcc.current as unknown as HTMLSelectElement)?.focus();
                  }, 100);
                }
                else if (field.name === 'Guardian Occupation') {
                  setTimeout(() => {
                    (maternalUncle.current as unknown as HTMLSelectElement)?.focus();
                  }, 100);
                }
                else if (field.name === 'Maternal uncle name') {
                  setTimeout(() => {
                    (familyAnnualIncome.current as unknown as HTMLSelectElement)?.focus();
                  }, 100);
                }
                else if (field.name === 'Family Annual Income') {
                  setTimeout(() => {
                    (familymembers.current as unknown as HTMLSelectElement)?.focus();
                  }, 100);
                }
                else if (field.name === 'Address*') {
                  setTimeout(() => {
                    (Locality.current as unknown as HTMLSelectElement)?.focus();
                  }, 100);
                }
                else if (field.name === 'Locality*') {
                  setTimeout(() => {
                    (City.current as unknown as HTMLSelectElement)?.focus();
                  }, 100);
                }
                else if (field.name === 'Locality*') {
                  setTimeout(() => {
                    (City.current as unknown as HTMLSelectElement)?.focus();
                  }, 100);
                }
            }}
          />
        );
      case 'select':
        return (
          <SelectInput
            {...methods.register(field.name as Path<T>, { required: field.registerProps?.required })}
            key={field.name}
            value={formValues['Gender']}
            field={field as SelectField}
          />
        );
      case 'datetime':
        return (
          <DateTimeInput
            {...methods.register(field.name as Path<T>, { required: field.registerProps?.required })}
            key={field.name}
            field={field as DateTimeField}
          />
        );
        case 'Time':
        return (
          <DateTimeInput
            {...methods.register(field.name as Path<T>, { required: field.registerProps?.required })}
            key={field.name}
            field={field as TimeField}
          />
        );
      case 'number':
        const { name, label, min, max, step } = field as NumberField;
        const numberProps = {
          min: typeof min === 'string' ? parseFloat(min) : min,
          max: typeof max === 'string' ? parseFloat(max) : max,
          step: typeof step === 'string' ? parseFloat(step) : step,
        };
        return (
          <div key={name} style={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)' }}>
            <NumberInput
              inputRef={ 
                  field.name === 'Family Members'
                ? familymembers
                :
                field.name === 'No. of Brothers*'
                ? noOfBro
                :    field.name === 'No. of sister*'
                ? noOfSIsters 
                :    field.name === 'No. of Brothers married'
                ? noOfBroMarried 
                :    field.name === 'No. of Sisters married'
                ? noOfsisMarried 
                : 
            undefined}
                onEnterPress={() => {
                  if (field.name === 'Family Members') {
                    noOfBro.current?.focus();
                  }
                  if (field.name === 'No. of Brothers*') {
                    noOfSIsters.current?.focus();
                  }
                  if (field.name === 'No. of sister*') {
                    noOfBroMarried.current?.focus();
                  }
                  if (field.name === 'No. of Brothers married') {
                    noOfsisMarried.current?.focus();
                  }
                  if (field.name === 'No. of Sisters married') {
                    adresss.current?.focus();
                  }
                }
                }
              {...methods.register(name as Path<T>)}
              label={label}
              min={numberProps.min}
              max={numberProps.max}
              step={numberProps.step}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
    {!show ? (
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(handleShowPreview)}
          style={{ borderRadius: 16 }}
        >
          <div className="form-container">
            {visibleSections.map((sectionName) => (
              <div key={sectionName} className="form-section">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    position:'relative'
                  }}
                >
                  <h2 style={{ color: "black" }}>{sectionName}</h2>
                
                </div>
  
                <div className="form-section2">{sections[sectionName].map(renderField)}</div>
              </div>
            ))}
  
            {currentPage === maxPage && (
              <>
           
                  <div>
                    <h2 style={{ color: "black" }}>Upload Image</h2>
                    <div style={{ flexDirection: "row" }}>
                      <img
                        src={img ? img : profilImage }
                        alt="Selected"
                        style={{ maxWidth: "300px", maxHeight: "300px" }}
                      />
                      <input
                        type="file"
                        accept="image/*"
                        onChange={onupload}
                        style={{ color: EColor.TEXTPINK, marginLeft: 10 }}
                      />
                    </div>
                  </div>
              
              </>
            )}
  
            {error && (
              <div
                className="error-message"
                style={{ color: "red", marginTop: "10px" }}
              >
                {error}
              </div>
            )}
  
            <div
              className="pagination-controls"
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              {currentPage > 1 && (
                <button
                  type="button"
                  style={{
                    backgroundColor: EColor.BROWN,
                    color: "#FFFFFF",
                    border: "none",
                    borderRadius: "8px",
                    padding: "10px 20px",
                    fontSize: "16px",
                    cursor: "pointer",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    transition: "background-color 0.3s, box-shadow 0.3s",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#e08585")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = EColor.BROWN)
                  }
                  onClick={handlePreviousPage}
                >
                  Previous
                </button>
              )}
  
              {currentPage < maxPage && (
                <button
                  type="button"
                  style={{
                    backgroundColor: EColor.BROWN,
                    color: "#FFFFFF",
                    border: "none",
                    borderRadius: "8px",
                    padding: "10px 20px",
                    fontSize: "16px",
                    cursor: "pointer",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    transition: "background-color 0.3s, box-shadow 0.3s",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "violet")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "#8A2BE2")
                  }
                  onClick={handleNextPage}
                >
                  {reducermobile.isregisterLoading ? 'Loading' : 'Next'}
              
                </button>
              )}
            </div>
  
            {currentPage === maxPage && (
              <button
                type="button"
                onClick={handleShowPreview}
                style={{
                  backgroundColor: EColor.BROWN,
                  color: "#FFFFFF",
                  border: "none",
                  borderRadius: "8px",
                  padding: "10px 20px",
                  fontSize: "16px",
                  cursor: "pointer",
                  alignSelf: "center",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor = "#e08585")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor = EColor.BROWN)
                }
              >
                {"Show Preview"}
              </button>
            )}
          </div>
        </form>
      </FormProvider>
    ) : (
      <ReadOnlyForm
        data={previewData}
        isAdmin={isAdmin}
        handelPrevivewPage={handelPrevivewPage}
      />
    )}
  </>
  
  );
  
};

export default ReusableForm;
