import React from 'react';
import image6 from '../../assets/Images/IMG_1080_final.jpg';
import image7 from '../../assets/Images/Home_page-18-11.jpeg';
import './ImageCarouselStyle.css';


  

const ImageCarousel = () => {
  return (
    <div className="imageCarouselMain">
      <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
        </div>
        <div className="carousel-inner" style={{ width:'95%',height:'95%', margin:'auto', }}>
          <div className="carousel-item active">
            <img src={image7} alt="Carousel 1" />
          </div>
          <div className="carousel-item">
            <img src={image6} alt="Carousel 2" />
          </div>
        </div>  
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
         <span className="carousel-control-next-icon" aria-hidden="true"></span>
        </button>
      </div>    
    </div>
  );
}

export default ImageCarousel;
