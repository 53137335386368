import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { APIKEYS } from "../../../utils/Keys";
import moment from "moment";
import Resizer from "react-image-file-resizer";
import {
  ERegister,
  IPaymentError,
  IRegisterPersonalApiResponse,
  IRegisterState,
  IUserData,
} from "./register.models";
import { toast } from "react-toastify";

// Initial state
const userInitialState: IRegisterState = {
  registerLoading: false,
  registerSuccess: null,
  registerError: "",
};

// Utility to convert Base64 to Blob/File
const convertBase64ToBlob = (
  base64: string,
  contentType = "image/jpeg"
): Blob => {
  const byteString = atob(base64.split(",")[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: contentType });
};

const convertBase64ToFile = (base64: string, fileName: string): File => {
  const blob = convertBase64ToBlob(base64);
  return new File([blob], fileName, { type: blob.type });
};

// Async thunk for customer registration
export const postCustomerRegistration = createAsyncThunk<
  IRegisterPersonalApiResponse,
  IUserData,
  { rejectValue: string }
>(ERegister.REGISTER, async (data, { rejectWithValue }) => {
  //console.log('dataaa',data)
  try {
    const tokenData = {
      username: "jainmatrimonyapi",
      password: "jainmatrimonyapi",
    };
    const tokenResponse = await axios.post(`${APIKEYS.API}verify`, tokenData);
    const token = tokenResponse.data.token;

    if (!token) return rejectWithValue("Token retrieval failed.");

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const headersFormData = {
      ...headers,
      "Content-Type": "multipart/form-data",
    };

    const sendData: IUserData = {
      primaryMobNumber: data.mobileNo,
      firstName: data.firstName,
      lastName: data.lastName,
      gender: data.gender,
      occupationCity: data.occupationCity,
      dateOfBirth: data.dateOfBirth,
      birthPlace: data.birthPlace,
      birthTime: moment(data.birthTime, "HH:mm").format("hh:mm A"),
      bloodGroup: data.bloodGroup,
      relWithGuardian: data.relWithGuardian,
      maritalStatus: data.maritalStatus,
      motherTongue: data.motherTongue,
      height: data.height,
      weight: " ",
      relationWithGuardian: data.relationWithGuardian,
      expectation: data.expectation,
      hobby: data.hobby,
      middleName: data.middleName,
      colourOfBody: data.colourOfBody,
      spec: data.spec,
      physicalStatus: data.physicalStatus,
      mobileNo: data.mobileNo,
      status: "A",
      agriculturalLand: data.agriculturalLand,
      profilePic: null,
      educationDetails: data.educationDetails,
      employedIn: data.employedIn,
      occupation: data.occupation,
      organization: data.organization,
      annualIncome: data.annualIncome,
      caste: data.caste,
      subcaste: data.subcaste,
      familyDetails: {
        ...data.familyDetails,
        familyAnnualIncome:
          data.familyAnnualIncome ?? data.familyDetails?.familyAnnualIncome,
      },
      customerCred: data.customerCred,
      addressDetails: data.addressDetails,
    };

    const phonePayResponse = await axios.get<IPaymentError>(
      `${APIKEYS.API}phonepe-payment/${data.transactionId}`,
      { headers }
    );
    if (!phonePayResponse.data.success)
      return rejectWithValue("Payment verification failed.");

    const registrationResponse = await axios.post<IRegisterPersonalApiResponse>(
      `${APIKEYS.API}customerweb`,
      sendData,
      { headers }
    );
    const custId = registrationResponse.data?.data?.custId;
    if (custId && data.fileName && data.newProfile) {
      //console.log("profilepicccccc---have ");
      const resizedImage = await resizeImage(
        convertBase64ToFile(data.newProfile, data.fileName),
        100
      );
      const imageBlob = convertBase64ToBlob(resizedImage);

      const formData = new FormData();
      formData.append("file", imageBlob, data.fileName);

      //console.log('hhhhhh',formData)
      const sendPostData = {
        paymentId: data.paymentId,
        paymentInstrument: phonePayResponse.data.data.paymentInstrument.type,
        utr: phonePayResponse.data.data.paymentInstrument.utr,
        status: "COMPLETED",
      };
      const a = await axios.put(
        `${APIKEYS.API}phonepe-payment/${custId}`,
        sendPostData,
        { headers }
      );
      if (a) {
        const profileResponse = await axios.post(
          `${APIKEYS.API}profile/${custId}`,
          formData,
          { headers: headersFormData }
        );
        if (profileResponse.data) {
          //console.log('sendPostData',sendPostData)
          const a = await axios.put(
            `${APIKEYS.API}phonepe-payment/${custId}`,
            sendPostData,
            { headers }
          );
          //console.log('sendPostData------a', a)
          sessionStorage.removeItem("paymentData");
          toast.success("Registered Successfully in Jain Utkarsh Mandal", {
            position: "top-right",
          });
        }
      }
    }
    if (custId && !data.fileName && !data.newProfile) {
      //console.log('profilepicccccc----not ')
      const sendPostData = {
        paymentId: data.paymentId,
        paymentInstrument: phonePayResponse.data.data.paymentInstrument.type,
        utr: phonePayResponse.data.data.paymentInstrument.utr,
        status: "COMPLETED",
      };
      //console.log('sendPostData',sendPostData)
      const a = await axios.put(
        `${APIKEYS.API}phonepe-payment/${custId}`,
        sendPostData,
        { headers }
      );
      //console.log('sendPostData------a', a)
      sessionStorage.removeItem("paymentData");
      toast.success("Registered Successfully in Jain Utkarsh Mandal", {
        position: "top-right",
      });
    }
    return registrationResponse.data;
  } catch (error) {
    console.error("Error during registration:", error);
    if (axios.isAxiosError(error) && error.response) {
      return rejectWithValue(error.response.data.message);
    }
    return rejectWithValue("Something went wrong.");
  }
});

// Function to resize image
const resizeImage = (file: File, quality: number): Promise<string> =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      quality,
      0,
      (uri) => resolve(uri as string),
      "base64"
    );
  });

// Slice for managing registration state
const postCustomerRegistrationSlice = createSlice({
  name: ERegister.REGISTER,
  initialState: userInitialState,
  reducers: {
    resetData: (state) => {
      state.registerSuccess = null;
      state.registerError = "";
      state.registerLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postCustomerRegistration.pending, (state) => {
        state.registerLoading = true;
      })
      .addCase(postCustomerRegistration.fulfilled, (state, action) => {
        state.registerLoading = false;
        state.registerSuccess = action.payload || null;
        state.registerError = null;
      })
      .addCase(postCustomerRegistration.rejected, (state, action) => {
        state.registerLoading = false;
        state.registerError = action.payload || "Something went wrong.";
      });
  },
});

export const { resetData } = postCustomerRegistrationSlice.actions;
export default postCustomerRegistrationSlice.reducer;
