import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Splash from '../../assets/Images/Splash.jpeg';
import logo from '../../assets/Images/logo.png';
import { EColor } from "../../styles/Colors/Colors";
import './navBar.css'

const Navbar = () => {
    const location = useLocation();
    const [activeLink, setActiveLink] = useState(location.pathname);

    const handleLinkClick = (path: string) => {
        setActiveLink(path);
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
                <div className="d-flex align-items-center">
                    <img src={logo} className="logo"  alt="Splash" />
                    <Link to="/" className="navbar-brand"><p className="travo" style={{color:"#d55d5d",fontSize:28}}>Jain Utkarsh Mandal</p></Link>
                </div>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-center" id="navbarTogglerDemo02">
                    <ul className="navbar-nav mx-auto mb-4 mb-lg-0">
                        <li className="nav-item">
                            <Link
                                to="/"
                                className="nav-link"
                                style={{
                                    color: EColor.DARKBLUE,
                                    fontWeight: "normal",
                                    textDecoration: 'none',
                                    backgroundColor: activeLink === "/" ? EColor.BROWN : EColor.WHITE,
                                    padding:16,
                                    borderRadius: activeLink === "/" ? "15px" : "0",
                                }}
                                onClick={() => handleLinkClick("/")}
                            >
                               <strong style={{ color: activeLink ===  "/"  ?   'white': '#d55d5d',fontSize:16}}>Home</strong>

                            </Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link
                                to="/Services"
                                className="nav-link"
                                style={{
                                    color:  EColor.DARKBLUE,
                                    fontWeight: "normal",
                                    textDecoration: 'none',
                                    padding:16,
                                    backgroundColor: activeLink === "/Services" ? EColor.BROWN : EColor.WHITE,
                                    borderRadius: activeLink === "/Services" ? "15px" : "0",
                                }}
                                onClick={() => handleLinkClick("/Services")}
                            >
                                <strong style={{ color: activeLink ===  "/Services"  ?  'white': EColor.BROWN,fontSize:16 }}>Features</strong>
                            </Link>
                        </li> */}
                        <li className="nav-item">
                            <Link
                                to="/Gallery"
                                className="nav-link"
                                style={{
                                    color:  EColor.DARKBLUE,
                                    fontWeight: "normal",
                                    textDecoration: 'none',
                                    padding:16,
                                    backgroundColor: activeLink === "/Gallery" ? EColor.BROWN : EColor.WHITE,
                                    borderRadius: activeLink === "/Gallery" ? "15px" : "0",
                                }}
                                onClick={() => handleLinkClick("/Gallery")}
                            >
                                <strong style={{ color:  activeLink === "/Gallery" ?  'white': EColor.BROWN,fontSize:16}} >Gallery</strong>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/AboutUs"
                                className="nav-link"
                                style={{
                                    color:  EColor.DARKBLUE,
                                    fontWeight: "normal",
                                    textDecoration: 'none',
                                    padding:16,
                                    backgroundColor: activeLink === "/AboutUs" ? EColor.BROWN : EColor.WHITE,
                                    borderRadius: activeLink === "/AboutUs" ? "15px" : "0",
                                }}
                                onClick={() => handleLinkClick("/AboutUs")}
                            >
                                <strong style={{ color:  activeLink === "/AboutUs" ?  'white': EColor.BROWN}}>About us</strong>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/ContactUs"
                                className="nav-link"
                                style={{
                                    color:  EColor.DARKBLUE,
                                    fontWeight: "normal",
                                    textDecoration: 'none',
                                    padding:16,
                                    backgroundColor: activeLink === "/ContactUs"? EColor.BROWN : EColor.WHITE,
                                    borderRadius: activeLink === "/ContactUs" ? "15px" : "0",
                                }}
                                onClick={() => handleLinkClick("/ContactUs")}
                            >
                                <strong style={{ color:  activeLink === "/ContactUs" ?  'white': EColor.BROWN,fontSize:16}} >Contact us</strong>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/LogIn"
                                className="nav-link"
                                style={{
                                    color:  EColor.DARKBLUE,
                                    fontWeight: "normal",
                                    textDecoration: 'none',
                                    padding:16,
                                    backgroundColor: activeLink === "/LogIn" ?  EColor.BROWN : EColor.WHITE,
                                    borderRadius: activeLink === "/LogIn" ? "15px" : "0",
                                }}
                                onClick={() => handleLinkClick("/LogIn")}
                            >
                                <strong style={{ color:  activeLink === "/LogIn" ?  'white': EColor.BROWN,fontSize:16}}>Register for Melawa</strong>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/LogInUser"
                                className="nav-link"
                                style={{
                                    color:  EColor.DARKBLUE,
                                    fontWeight: "normal",
                                    textDecoration: 'none',
                                    padding:16,
                                    backgroundColor: activeLink === "/LogInUser" ? EColor.BROWN : EColor.WHITE,
                                    borderRadius: activeLink === "/LogInUser" ? "15px" : "0",
                                }}
                                onClick={() => handleLinkClick("/LogInUser")}
                            >
                                <strong style={{ color:  activeLink === "/LogInUser" ?  'white': EColor.BROWN,fontSize:16}}>Admin Login</strong>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/SampleForm"
                                className="nav-link"
                                style={{
                                    color:  EColor.DARKBLUE,
                                    fontWeight: "normal",
                                    textDecoration: 'none',
                                    padding:16,
                                    backgroundColor: activeLink === "/SampleForm" ? EColor.BROWN : EColor.WHITE,
                                    borderRadius: activeLink === "/SampleForm" ? "15px" : "0",
                                }}
                                onClick={() => window.open('/SampleForm', '_blank')}
                            >
                                <strong style={{ color:  activeLink === "/SampleForm" ?  'white': EColor.BROWN,fontSize:16}}>Sample Form</strong>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar;
