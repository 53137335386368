import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { APIKEYS } from "../../../utils/Keys";
import moment from "moment";
import Resizer from 'react-image-file-resizer';
import { EUpdate, IUpdateApiResponse, IUpdateState, IUserData } from "./updateCustomer.models";
import { toast } from "react-toastify";

// Initial state
const userInitialState: IUpdateState = {
  registerLoading: false,
  registerSuccess: null,
  registerError: '',
};

// Async thunk for customer update
export const CustomerUpdate = createAsyncThunk<IUpdateApiResponse, IUserData, { rejectValue: string }>(
    EUpdate.REGISTER,
  async (data, { rejectWithValue }) => {
    //console.log('dataaa',data)
    try {
      const tokenData = { username: "jainmatrimonyapi", password: "jainmatrimonyapi" };
      const tokenResponse = await axios.post(`${APIKEYS.API}verify`, tokenData);
      const token = tokenResponse.data.token;

      if (!token) return rejectWithValue('Token retrieval failed.');

      const headers = { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' };
      

    //   const sendData: IUserData = {
    //     custId: data.custId,
    //     primaryMobNumber: data.mobileNo,
    //     firstName: data.firstName,
    //     lastName: data.lastName,
    //     gender: data.gender,
    //     occupationCity: data.occupationCity,
    //     dateOfBirth: data.dateOfBirth,
    //     birthPlace: data.birthPlace,
    //     birthTime: moment(data.birthTime, 'HH:mm').format('hh:mm A'),
    //     bloodGroup: data.bloodGroup,
    //     relWithGuardian: data.relWithGuardian,
    //     maritalStatus: data.maritalStatus,
    //     motherTongue: data.motherTongue,
    //     height: data.height,
    //     weight: " ",
    //     relationWithGuardian: data.relationWithGuardian,
    //     expectation: data.expectation,
    //     hobby: data.hobby,
    //     middleName: data.middleName,
    //     colourOfBody: data.colourOfBody,
    //     spec: data.spec,
    //     physicalStatus: data.physicalStatus,
    //     mobileNo: data.mobileNo,
    //     status: 'A',
    //     agriculturalLand: data.agriculturalLand,
        
    //     educationDetails: data.educationDetails,
    //     employedIn: data.employedIn,
    //     occupation: data.occupation,
    //     organization: data.organization,
    //     annualIncome: data.annualIncome,
    //     caste: data.caste,
    //     subcaste: data.subcaste,
    //     familyDetails: {
    //       ...data.familyDetails,
    //       familyAnnualIncome: data.familyAnnualIncome ?? data.familyDetails?.familyAnnualIncome,
    //       ...data.familyDetails,
    //       fathersName: data.fathersName ?? data.familyDetails?.fathersName,
    //     },
       
    //   };
        const sendData ={
            custId: data.custId,
            firstName: data.firstName,
            middleName: data.middleName,
            lastName: data.lastName,
            dateOfBirth: data.dateOfBirth,
                gender: data.gender,
                primaryMobNumber: data.primaryMobNumber,
                birthTime: data.birthTime,
                birthPlace: data.birthPlace,
                motherTongue: data.motherTongue,
                maritalStatus: data.maritalStatus,
                physicalStatus: data.physicalStatus,
                height: data.height,
                weight: data.weight,
                hobby:data.hobby ,
                colourOfBody:data.colourOfBody ,
                spec: data.spec,
                bloodGroup:data.bloodGroup,
                educationDetails:data.educationDetails,
                employedIn: data.employedIn,
                occupation: data.occupation,
                occupationCity: data.occupationCity,
                organization: data.organization,
                annualIncome: data.annualIncome,
                agriculturalLand: data.agriculturalLand,
                caste: data.caste,
                subcaste: data.subcaste,
                readyToMarryWithinSubcast:data.readyToMarryWithinSubcast,
                expectation: data.expectation,
                status: data.status,
                relationWithGuardian: data.relationWithGuardian,
                familyDetails: {
                    fathersName:  data.familyDetails?.fathersName,
                    houseOwnership: data.familyDetails?.houseOwnership,
                    maternalUnclesName: data.familyDetails?.maternalUnclesName,
                    fathersMobileNo: data.familyDetails?.fathersMobileNo,
                    fathersOccupation: data.familyDetails?.fathersOccupation

                }
                
            
        }
      

      const updateResponse = await axios.put<IUpdateApiResponse>(`${APIKEYS.API}customer`, sendData, { headers });
      //console.log(updateResponse)
      return updateResponse.data;
    } catch (error) {
      console.error('Error during registration:', error);
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue('Something went wrong.');
    }
  }
);



const putCustomerUpdateSlice = createSlice({
  name: EUpdate.REGISTER,
  initialState: userInitialState,
  reducers: {
    resetData: (state) => {
      state.registerSuccess = null;
      state.registerError = '';
      state.registerLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(CustomerUpdate.pending, (state) => {
        state.registerLoading = true;
      })
      .addCase(CustomerUpdate.fulfilled, (state, action) => {
        state.registerLoading = false;
        state.registerSuccess = action.payload || null;
        state.registerError = null;
      })
      .addCase(CustomerUpdate.rejected, (state, action) => {
        state.registerLoading = false;
        state.registerError = action.payload || 'Something went wrong.';
      });
  },
});

export const { resetData } = putCustomerUpdateSlice.actions;
export default putCustomerUpdateSlice.reducer;
