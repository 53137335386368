import React, { useEffect, useState } from 'react';
import './ReadOnlyForm.css'; // Import custom CSS
import { EColor } from '../../styles/Colors/Colors';
import { useAppDispatch } from '../../states/store';
import { postCustomerRegistration } from '../../states/Slice/registerUser/register.slice';
import { toast,ToastContainer } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap';
import Resizer from "react-image-file-resizer";
import { useNavigate, useLocation } from 'react-router-dom';
import { initiatePhonePay } from '../../states/Slice/phonePay/Phonepay.slice';
import { postAdminCustomerRegistration } from '../../states/Slice/admInRegister/adminRegister.slice';
import profilePic from '../../assets/Images/profilepicdefault.png'

const ReadOnlyForm = ({ data,isAdmin,handelPrevivewPage }) => {

const location = useLocation();
const navigation = useNavigate()
const [showModal, setShowModal] = useState(false);
const [datas, setDatas] = useState(null);

  const handleSubmit = () => {
    setShowModal(true); 
  };

  //console.log('fff',isAdmin,data)
  

const dispatch = useAppDispatch()
const resizeFile = (file) =>
    new Promise((resolve, reject) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
            if(uri){
                resolve(uri);
            }else {
                reject("Error");
            }
          
          
        },
        "base64"
      );
    });
const onsubmit = () => {
    if (isAdmin) {
      dispatch(postAdminCustomerRegistration(data)).then((res) => {
        if (res.payload) {
          toast.success('Registered Successfully At Jain Utkarsh Mandal', {
            position: 'top-right',
          });
          navigation("/CustomerTable");
        }
      });
    } else {
      const payLoad = {
        number: data.primaryMobNumber,
        amount: "1200",
      };
      dispatch(postAdminCustomerRegistration(data)).then((res1) => {
        if (res1.payload) {
          toast.success('Registered Successfully At Jain Utkarsh Mandal!! Please complete Payment to complete Registration Process!!', {
            position: 'top-right',
          });
        
    
        dispatch(initiatePhonePay(payLoad)).then((res) => {
          if (res.payload) {
            const newdata = {
                ...data,
                custId:res1.payload.data.custId,
                transactionId: res.payload.data.transactionId,
                paymentId: res.payload.data.paymentId,
                newProfile: null,
                fileName: null,
              };
    
              // Store data in local storage and update state
              sessionStorage.removeItem('paymentData');
              sessionStorage.setItem('paymentData', JSON.stringify(newdata));
              //navigation("/QRCodePage");
              setDatas(newdata);
           if (res.payload.data.url) {
                    window.location.href = res.payload.data.url;
                  }
            
          }
        }).catch(
            (error)=>{
                if(error){
                    toast.error('We are unable to process Payment at this moment! Please call us at 8380051108 to confirm registration.', {
                        position: 'top-right',
                      });
                }
            }
        );
      }
    }
    ).catch(
        (error)=>{
            if(error){
                toast.error('Registration Failed Please Contact : 8380051108', {
                    position: 'top-right',
                  });
            }
        }
    );
    }
  };
  
  
  

    const handleClose = () => {
        onsubmit()
        setShowModal(false); 
      };

    return (
        <><Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Submission Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>Once you submit, you will not be able to edit your details. Please confirm before proceeding?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal><form className="read-only-form">
                <fieldset className="fieldset-section">
                    <legend style={{color: EColor.BROWN}}>Personal Information</legend>

                    <div className="form-group">
                        <label>Candidate's Full Name:</label>
                        <input type="text" value={`${data?.firstName || ''} ${data?.middleName || ''} ${data?.lastName || ''}`} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Guardian's Full Name:</label>
                        <input type="text" value={data?.familyDetails?.fathersName || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Address:</label>
                        <textarea value={`${data?.addressDetails[0]?.addr || ''}. ${data?.addressDetails[0]?.pincode || ''}`} readOnly />
                    </div>
                    <div className="form-group">
                        <label> Mobile Number 1:</label>
                        <input type="text" value={data?.primaryMobNumber || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label> Mobile Number 2:</label>
                        <input type="text" value={data?.familyDetails.fathersMobileNo || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Date of Birth:</label>
                        <input type="text" value={data?.dateOfBirth || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Birth Time:</label>
                        <input type="text" value={data?.birthTime || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Education:</label>
                        <input type="text" value={data?.educationDetails || ''} readOnly />
                    </div>
                    {
                        data?.employedIn.toLowerCase() != 'not working' &&(
                            <><div className="form-group">
                            <label>Occupation:</label>
                            <input type="text" value={data?.occupation || ''} readOnly />
                        </div>
                        <div className="form-group">
                            <label>Occupation City:</label>
                            <input type="text" value={data?.occupationCity || ''} readOnly />
                        </div><div className="form-group">
                            <label>Annual Income:</label>
                            <input type="text" value={`${data?.annualIncome} Lakhs` || ''} readOnly />
                        </div>
                                </>
                        )
                    }
                    <div className="form-group">
                        <label>Sub-Cast:</label>
                        <input type="text" value={data?.subcaste || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Expectation:</label>
                        <input type="text" value={data?.expectation || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Height:</label>
                        <input type="text" value={data?.height || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Body Color:</label>
                        <input type="text" value={data?.colourOfBody || ''} readOnly />
                    </div>
                    {data?.gender.toString() == "Male" &&  <div className="form-group">
                        <label>Agricultural Land:</label>
                        <input type="text" value={data.agriculturalLand || ''} readOnly />
                    </div>}

                    <div className="form-group">
                        <label>Gender:</label>
                        <input type="text" value={data?.gender || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Birth Place:</label>
                        <input type="text" value={data?.birthPlace || ''} readOnly />
                    </div>
                   {data?.gender.toString() == "Male" &&  <div className="form-group">
                        <label>Own House:</label>
                        <input type="text" value={data?.familyDetails.houseOwnership || ''} readOnly />
                    </div>}
                    <div className="form-group">
                        <label>Blood Group:</label>
                        <input type="text" value={data?.bloodGroup || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Marital Status:</label>
                        <input type="text" value={data?.maritalStatus || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Cast:</label>
                        <input type="text" value={data?.caste || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Mother Tongue:</label>
                        <input type="text" value={data?.motherTongue || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Employed In:</label>
                        <input type="text" value={data?.employedIn || ''} readOnly />
                    </div>
                    {
                        data?.employedIn.toLowerCase() != 'not working' &&(
                            <><div className="form-group">
                                <label>Organization:</label>
                                <input type="text" value={data?.organization || ''} readOnly />
                            </div>
                                </>
                        )
                    }
                </fieldset>

                <fieldset className="fieldset-section">
                    <legend style={{color: EColor.BROWN}}>Family Details</legend>
                  
                    <div className="form-group">
                        <label>Releation with Guardian:</label>
                        <input type="text" value={data?.relationWithGuardian || '0'} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Guardian's Occupation:</label>
                        <input type="text" value={data?.familyDetails?.fathersOccupation || '0'} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Family Members:</label>
                        <input type="text" value={data?.familyDetails?.familyMembers || '0'} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Family Members:</label>
                        <input type="text" value={data?.familyDetails?.familyMembers || '0'} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Number of Brothers:</label>
                        <input type="text" value={data?.familyDetails?.numberOfBrothers || '0'} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Number of Sisters:</label>
                        <input type="text" value={data?.familyDetails?.numberOfSisters || '0'} readOnly />
                    </div>
                </fieldset>

                <fieldset className="fieldset-section">
                    <legend style={{color: EColor.BROWN}}>Address Details</legend>
                    <div className="form-group">
                        <label>Locality:</label>
                        <input type="text" value={data?.addressDetails[0]?.locality || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label>City:</label>
                        <input type="text" value={data?.addressDetails[0]?.city || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label>State:</label>
                        <input type="text" value={data?.addressDetails[0]?.state || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Country:</label>
                        <input type="text" value={data?.addressDetails[0]?.country || ''} readOnly />
                    </div>
                </fieldset>

                <h2>Profile Pic</h2>
                <div className="image-upload">
                    <img src={data?.uri ? data?.uri : profilePic} alt="Selected" className="uploaded-img" />
                </div>
                <button onClick={() => handelPrevivewPage()}  
          style={{
                      backgroundColor:EColor.BROWN,
                      color: '#FFFFFF',
                      border: 'none',
                      borderRadius: '8px',
                      padding: '10px 20px',
                      fontSize: '16px',
                      cursor: 'pointer',
                      alignSelf: 'center',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                      transition: 'background-color 0.3s, box-shadow 0.3s',
                    }}
                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e08585'}
                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = EColor.BROWN}
                    >
          Edit
            </button>
                <button
                    type="button"
                    onClick={handleSubmit}
                    style={{
                        backgroundColor: EColor.BROWN,
                        color: '#FFFFFF',
                        alignSelf: "center",
                        marginTop: 10,
                        border: 'none',
                        borderRadius: '8px',
                        padding: '10px 20px',
                        fontSize: '16px',
                        width: '100%',
                        cursor: 'pointer',
                        alignSelf: 'center',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                        transition: 'background-color 0.3s, box-shadow 0.3s',
                    }}
                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e08585'}
                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = EColor.BROWN}
                >
               {isAdmin ? 'Submit Details' : ' Pay 1200 rs. '}  
                </button>
            </form></>
    );
};

export default ReadOnlyForm;
